import { Typography } from "components/typography"
import { Link } from "gatsby"
import React from "react"

export default function NotFound() {
  return (
    <div className="page-container">
      <Typography.P>Page not found, head &nbsp;</Typography.P>
      <Link to="/">
        <Typography.P> home</Typography.P>
      </Link>
      <Typography.P>.</Typography.P>
    </div>
  )
}
