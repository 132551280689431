import Layout from "components/layout"
import NotFound from "components/not-found"
import React from "react"

export default function NotFoundPage() {
  return (
    <Layout>
      <NotFound />
    </Layout>
  )
}
